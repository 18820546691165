.app-form
{
    display: flex;
    flex-direction: column;

    input:not(.ts-wrapper input)
    {
        font-size: 1.6em;
        height: 50px;
        width: 100%;
        padding: 0px 18px;
        margin-bottom: 15px;
        border-radius: 6px;
        border: 1px solid $muted;
        outline: 0;
        transition: all .2s ease;
        color: #000;
        background: #fff;

        &:hover,
        &:focus
        {
            &~.placeholder
            {
                visibility: hidden;
                opacity: 0;
            }
        }

        &::placeholder
        {
            color: $muted;
        }
    }

    label
    {
        font-weight: 400;
        font-size: 1.4em;
    }

    p
    {
        font-weight: 400;
        font-size: 1.5em;
    }

    .placeholder
    {
        font-weight: 400;
        font-size: 1.4em;
        pointer-events: none;
        position: absolute;
        display: flex;
        align-items: center;
        top: 50%;
        left: 15px;
        width: 90px;
        height: 35px;
        transform: translateY(-50%);
        color: $muted;
        background: #fff;
        transition: all .2s ease;

        &.hidden
        {
            visibility: hidden;
            opacity: 0;
        }
    }

    i.fa-exchange
    {
        position: relative;
        cursor: pointer;
        font-size: 1.8em;

        @include mq('phablet', max)
        {
            font-size: 2em;
            top: 15px;
            transform: rotate(90deg);
        }
    }
}