.introSlider
{
    position: relative;
    overflow: hidden;
    margin-top: 80px;
    width: 100%;
    height: 100%;

    .swiper-slide
    {
        display: flex;
        align-items: center;
        height: auto;
        padding: 80px 0 120px 0;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: right center;

        @include mq('tablet', max)
        {
            padding: 50px 0;
        }
    }

    .swp-next,
    .swp-prev
    {
        position: absolute;
        top: 50%;
        font-size: 2.4em;
        z-index: 1;

        @include mq('tablet', max)
        {
            top: auto;
            bottom: 10px;
        }
    }

    .swp-next
    {
        right: 50px;

        @include mq('desktop', max)
        {
            right: 15px;
        }
    }

    .swp-prev
    {
        left: 50px;

        @include mq('desktop', max)
        {
            left: 15px;
        }
    }

    @include mq('desktop-small', max)
    {
        margin-top: 80px;
    }
}