// Dropdown
.app-drop
{
    position: relative;
    display: inline-block;

    &-content
    {
        position: absolute;
        visibility: hidden;
        width: 190px;
        height: auto;
        top: 100%;
        right: 50%;
        padding-top: 10px;
        opacity: 0;
        transform: translateX(50%);
        transition: 0;
        
        &-body
        {
            position: relative;
            width: 100%;
            height: auto;
            padding: 10px;
            border-radius: 8px;
            box-shadow: 0px 0px 46px rgba(0, 0, 0, 0.15);
            background: #fff;
            
            a
            {
                display: block;
                width: 100%;
                padding: 10px;
                border-radius: 6px;
                color: #000;
                transition: all .3s ease;

                &:hover
                {
                    color: #000;
                    background: #e7e7e7;
                }
            }
        }

        &.left
        {
            left: 0;
            right: auto;
            transform: none;
        }

        &.wide
        {
            width: 250px;
        }
    }

    &:hover
    {
        .app-drop-content
        {
            visibility: visible;
            opacity: 1;
            transition: all .3s ease;
        }
    }
}

// Same but for mobile devices
.app-drop-mob
{
    cursor: pointer;
    overflow: hidden;
    transition: all .3s ease;

    &-body
    {
        visibility: hidden;
        max-height: 0;
        transition: all .4s ease;
        padding: 0;
        opacity: 0;

        a
        {
            font-weight: 400;
            font-size: 16px!important;
        }
    }

    &.active
    {
        padding-top: 10px;
        margin-top: 10px;

        .app-drop-mob-body
        {
            visibility: visible;
            max-height: 2000px;
            padding: 15px 0;
            margin-bottom: 10px;
            opacity: 1;
        }
    }
}

// Section title
.sec-tle
{
    font-weight: 400;
    font-size: 3em;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 30px;
    color: $dark-blue;

    a
    {
        text-decoration: underline;
        font-weight: 400;
        font-size: 0.45em!important;
        margin: 0!important;
        color: #fff!important;
    }

    @include mq('tablet', max)
    {
        font-size: 2.4em;
        margin-bottom: 15px;
    }
}

// Tab
.app-tab
{
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: 1px solid #848484;
    border-top-left-radius: 6px;
    border-top-right-radius: 6px;
    color: #fff;
    background: #00000094;

    .nav-item
    {
        width: calc(100% / 3);

        &:nth-child(2)
        {
            border-left: 1px solid #848484;
            border-right: 1px solid #848484;
        }
    }
    
    .nav-link
    {
        text-align: center;
        font-size: 1.4em;
        display: flex;
        align-items: center;
        justify-content: center;
        height: 70px;
        border: none!important;
        border-radius: 0;

        img
        {
            margin-right: 10px;
        }

        &.active
        {
            color: #fff;
            background: $dark-blue;
        }
    }

    &~.tab-content
    {
        padding: 15px 40px;
        color: #fff;
        background: $dark-blue;
    }

    @include mq('phablet', max)
    {
        flex-direction: column;

        .nav-item
        {
            width: 100%;

            &:nth-child(2)
            {
                border: none;
            }
        }
    }
}

// Social

.social
{
    font-size: 1.6em;
    text-decoration: none!important;
    display: flex!important;
    align-items: center;
    justify-content: center;
    width: 30px!important;
    height: 30px!important;
    margin: 0 7px;
    border-radius: 50%;
    color: $dark-blue;
    background: #fff;
    transition: all .2s ease;

    &.reverse
    {
        color: #fff;
        background: #333;
    }

    &:hover
    {
        color: #262626;
        background: #fff;
    }
}

// Bread Crumb

.app-bread-crumb
{
    font-size: 1.4em;
    display: flex;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    
    li
    {
        list-style-type: none;
        color: $muted;

        &.active
        {
            color: $primary;
        }
        
        &::after
        {
            content: '/';
            margin-left: 5px;
            margin-right: 10px;
            color: #000;
        }
    
        &:last-child
        {
            &::after
            {
                display: none;
            }
        }

        a:hover
        {
            text-decoration: underline;
        }
    }
}

// Collapse

.app-collapse
{
    font-weight: 500;
    font-size: 1.6em;
    padding: 0;
    margin: 0;
    padding: 15px;
    border-radius: 6px;
    color: #000;
    background: $dark-blue;

    li
    {
        list-style-type: none;
        padding: 12px!important;
        border-radius: 4px;
        color: #fff;

        a
        {
            display: block;
            width: 100%;
        }
        
        &.selected
        {
            color: $muted;
            background: #fff!important;

            i
            {
                color: $muted;
            }
        }

        &.active
        {
            & > .d-flex
            {
                font-size: 1.2em;
                color: $primary;
            }
        }

        &:first-child
        {
            margin-top: 0!important;
        }
    }
    
    i
    {
        font-size: 14px;
    }

    .app-drop-mob
    {
        &.active
        {
            i
            {
                &::before
                {
                    content: '\f0de';
                }
            }
        }
    }

    .app-drop-mob-body
    {
        padding-bottom: 0!important;
        margin-bottom: 0!important;
    }
    
    &.second
    {
        padding: 0;
        background: transparent;

        li
        {
            padding: 12px 0!important;
            border-radius: 0px!important;
            border-bottom: 1px solid $info;
            color: $dark-blue;

            &.active
            {
                & > .d-flex
                {
                    font-size: 16px;
                    color: $dark-blue;
                }
            }

            &:first-child
            {
                padding-top: 0!important;
            }
        }
    }
}

// World Map

.world-map
{
    .country
    {
        transition: all .2s ease;

        &:hover
        {
            fill: #27336529;
        }
    }

    .country-name
    {
        fill: #000;
    }
}