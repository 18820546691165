// ProximaNova ====================================================================

@font-face {
    font-family: 'proxima-nova';
    src: url('../../fonts/ProximaNova/ProximaNova-Light.ttf')  format('truetype'),
         url('../../fonts/ProximaNova/ProximaNova-Light.woff')  format('woff');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'proxima-nova';
    src: url('../fonts/ProximaNova/ProximaNova-Regular.ttf')  format('truetype'),
         url('../fonts/ProximaNova/ProximaNova-Regular.woff')  format('woff');
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: 'proxima-nova';
    src: url('../fonts/ProximaNova/ProximaNova-Semibold.ttf')  format('truetype'),
         url('../fonts/ProximaNova/ProximaNova-Semibold.woff')  format('woff');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'proxima-nova';
    src: url('../fonts/ProximaNova/ProximaNova-Bold.ttf')  format('truetype'),
         url('../fonts/ProximaNova/ProximaNova-Bold.woff')  format('woff');
    font-weight: bold;
    font-style: normal;
}
@font-face {
    font-family: 'proxima-nova';
    src: url('../fonts/ProximaNova/ProximaNova-Extrabld.ttf')  format('truetype'),
         url('../fonts/ProximaNova/ProximaNova-Extrabld.woff')  format('woff');
    font-weight: 800;
    font-style: normal;
}