/**
  Переменные для цветов
 */

$primary: #3182c4;
$light-primary: #54b0fa;
$secondary: #262626;
$success: #17ec71;
$danger: #eb3323;
$warning: #ffda00;
$info: #e2e8f1;
$dark: #343a40;
$muted: #374957;
$light-muted: #656c7b;
$light: #f8f9fa;
$white: #fff;
$purple: #7F68C8;
$black: #000;
$brown: #AC7D75;
$dark-blue: #263366;