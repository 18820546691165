.modal
{
    background: #000000b0;
    
    .modal-dialog
    {
        top: 45%;
        max-width: 700px;
        transform: translateY(-50%)!important;
    }
    
    .modal-content
    {
        width: 670px;
        max-width: 100%;
        margin: 0 auto;
    }

    &-body
    {
        padding: 30px;

        h5
        {
            font-weight: 400;
            font-size: 3em;
            margin-bottom: 5px;
            color: $dark-blue;
        }

        h6
        {
            font-weight: 400;
            font-size: 1.8em;
            margin-bottom: 10px;

            @include mq('phablet', max)
            {
                text-align: center;
            }
        }

        a
        {
            font-size: 1.6em;
            display: inline-block;
            width: 100%;
            margin-bottom: 2px;
            color: $muted;

            @include mq('phablet', max)
            {
                text-align: center;
            }
        }

        .close
        {
            font-weight: 300;
            cursor: pointer;
        }
    }
}