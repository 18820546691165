.way
{
    &-img
    {
        object-fit: cover;
        object-position: center;
        width: 100%;
        height: 400px;
    }

    &-card
    {
        width: 100%;
        height: auto;
        padding: 0 15px;
        transform: translateY(-50%);

        &-body
        {
            width: 100%;
            padding: 15px;
            border-radius: 6px;
            box-shadow: 0px 10px 65px rgba(0, 0, 0, 0.06);
            background: #fff;
        }

        span
        {
            font-size: 1.4em;
            color: $light-muted;
        }

        h5
        {
            font-size: 2em;
            margin-bottom: 0;
        }

        .way-card-country
        {
            color: $light-muted;
            margin-bottom: 15px;
        }

        .way-card-price
        {
            display: flex;
            justify-content: space-between;
            align-items: center;
            width: 100%;
            color: $primary;
        }
    }

    @include mq('tablet', max)
    {
        &-img
        {
            height: 250px;
        }

        &-card
        {
            transform: translateY(-20%);
        }
    }
}

.news
{
    color: #fff;

    &-img
    {
        width: 190px;
        height: 165px;
        object-fit: cover;
        object-position: center;
        border-radius: 6px;
    }

    p
    {
        font-size: 1.4em;
        margin-bottom: 0;

        @include textClamp(3);
    }

    a
    {
        font-size: 1.6em;
        display: block;
        margin: 15px 0;
        color: $light-primary;
    }

    @include mq('phablet', max)
    {
        text-align: center;

        img
        {
            margin-bottom: 10px;
        }
    }
}

.services
{
    &-card
    {
        position: relative;
        display: flex;
        justify-content: space-between;
        overflow: hidden;
        width: 100%;
        height: 100%;
        padding: 20px;
        border-radius: 6px;
        color: #fff;

        &-body
        {
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 50%;
            height: auto;
            z-index: 1;

            h5
            {
                font-size: 2.4em;
                margin-bottom: 10px;
            }

            p
            {
                font-size: 1.6em;
                margin-bottom: 30px;
            }
        }
    }

    img
    {
        pointer-events: none;
        object-fit: contain;
        object-position: right;
        width: 50%;
        height: 190px;

        &.out
        {
            object-fit: cover;
            object-position: center;
            right: 0!important;
        }
    }

    @include mq('phablet', max)
    {
        &-card-body
        {
            width: 100%;
        }

        img
        {
            position: absolute;
            top: 15px;
            right: 15px;
            height: calc(100% - 30px);
            opacity: .4;
        }
    }
}

.baggage
{
    &-card
    {
        position: relative;
        display: flex;
        justify-content: space-between;
        overflow: hidden;
        width: 100%;
        height: 100%;
        padding: 20px;
        border-radius: 6px;
        color: #000;

        &-body
        {
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 50%;
            height: auto;
            z-index: 1;

            h5
            {
                font-weight: 400;
                font-size: 4.2em;
                margin-bottom: 0;
            }
        }
    }

    img
    {
        pointer-events: none;
        object-fit: contain;
        object-position: right;
        width: 50%;
        height: 190px;

        &.out
        {
            object-fit: cover;
            object-position: center;
            right: 0!important;
        }
    }

    @include mq('phablet', max)
    {
        &-card-body
        {
            width: 100%;
        }

        img
        {
            position: absolute;
            top: 15px;
            right: 15px;
            height: calc(100% - 30px);
            opacity: .4;
        }
    }
}

.stock
{
    .row
    {
        .col-12
        {
            display: flex;
            padding: 20px;
            border-radius: 6px;
            box-shadow: 0px 0px 35px rgba(0, 0, 0, 0.08);

            img
            {
                object-fit: cover;
                object-position: center;
                width: 195px;
                border-radius: 6px;
                margin-right: 20px;
            }

            span
            {
                font-size: 1.6em;
                display: block;
                margin-bottom: 10px;
                color: $light-muted;
            }

            div a
            {
                display: block;
                font-size: 1.8em;
                margin-bottom: 10px;
            }

            p
            {
                font-weight: 300;
                margin-bottom: 0;
                color: $light-muted;
                
                @include textClamp(3);
            }

            &:first-child
            {
                margin-top: 0!important;
            }

            @include mq('tablet', max)
            {
                flex-direction: column;
                text-align: center;

                img
                {
                    margin-bottom: 15px;
                }
            }
        }
    }
}