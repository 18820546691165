.app-btn
{
    font-weight: 400;
    font-size: 1.4em;
    user-select: none;
    position: relative;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 180px;
    max-width: 100%;
    height: 42px;
    border-radius: 6px;
    border: 1px solid #fff;
    outline: 0!important;
    transition: .15s ease;
    background: transparent;

    &:hover
    {
        color: #000;
        background: #fff;
    }

    &-danger
    {
        border: 1px solid transparent;
        color: #fff;
        background: $danger;

        &:hover
        {
            border-color: $danger;
            color: $danger;
            background: transparent;
        }
    }

    &-dark
    {
        border: 1px solid #000;
        color: #000;
        background: transparent;

        &:hover
        {
            color: #fff;
            background: #000;
        }
    }
    
    &-light
    {
        border: 1px solid #fff;
        color: #000;
        background: #fff;

        &:hover
        {
            color: #fff;
            background: transparent;
        }
    }
    
    &-warning
    {
        border: 1px solid $warning;
        color: #000;
        background: $warning;

        &:hover
        {
            color: $warning;
            background: transparent;
        }
    }

    &-primary
    {
        width: 220px;
        height: auto;
        padding: 10px 15px;
        border: 1px solid $dark-blue;
        color: #fff;
        background: $dark-blue;

        i
        {
            margin-left: 15px;
        }

        &:hover
        {
            color: #fff;
            background: $dark-blue;
            box-shadow: 0px 18px 21px rgba(38, 51, 102, 0.35);
        }
    }

    &.wide
    {
        height: 50px;
    }

    &.circle
    {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 26px;
        height: 26px;
        padding: 0;
        border-radius: 50%;
        color: $muted;
        background: #EDF0F5;

        &.active
        {
            border: 1px solid $primary;
            color: $primary;
            background: #3182c429;
        }
    }

    &:active
    {
        transform: scale(.85);
    }
}