main
{
    position: relative;
    width: 100%;
    height: auto;

    section
    {
        position: relative;
        width: 100%;
        height: auto;
        padding-top: 80px;
        
        &.py
        {
            padding: 80px 0;
            
            @include mq('tablet', max)
            {
                padding: 50px 0;
            }
        }

        &.pt
        {
            padding-top: 50px;
        }
        
        @include mq('tablet', max)
        {
            padding-top: 50px;

            &.py
            {
                padding: 50px 0;
            }
        }
    }

    // Main Page
    
    .intro
    {
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        height: auto;
        padding-top: 0;

        .introSlider
        {
            color: #fff;

            .col-md-6
            {
                display: flex;
                flex-direction: column;
                justify-content: center;
            }

            h1
            {
                font-weight: 400;
                font-size: 3.2em;
                margin-bottom: 30px;
            }

            p
            {
                font-size: 1.6em;
                margin-bottom: 70px;
            }

            img
            {
                width: 100%;
            }

            @include mq('tablet', max)
            {
                text-align: center;

                button
                {
                    margin: 0 auto;
                }

                img
                {
                    display: none;
                }
            }

            @include mq('phablet', max)
            {
                h1
                {
                    font-size: 3.8em;
                    margin-bottom: 20px;
                }

                p
                {
                    margin-bottom: 50px;
                }
            }
        }
    }

    .app-widget
    {
        padding-top: 0;
        transform: translateY(-70px);
        z-index: 1;

        .app-drop
        {
            font-size: 1.4em;
            
            b,
            p
            {
                font-size: 14px;
                color: $muted;
            }

            input
            {
                text-align: center;
                font-size: 14px;
                display: inline;
                width: 20px;
                height: auto;
                padding: 0;
                margin: 0;
                border: 0;
                outline: 0;
                color: #fff;
                background: transparent;
            }

            & > div input
            {
                color: #000;
            }
        }

        @include mq('tablet', max)
        {
            padding-top: 50px;
            transform: none;
        }
    }

    .way
    {
        p
        {
            font-size: 1.6em;
            margin-bottom: 30px;
            color: $muted;
        }
    }

    .world-map
    {
        overflow-x: auto;
        overflow-y: hidden;

        &-info
        {
            position: absolute;
            display: none;
            top: 50%;
            left: 0;
            width: 300px;
            height: auto;
            z-index: 2;
            padding: 20px;
            box-shadow: 0 10px 20px #0000002a;
            transform: translateY(-50%);
            color: $dark-blue;
            background: #fff;

            h6
            {
                font-weight: 700;
                font-size: 1.5em;
                display: flex;
                align-items: center;
                justify-content: space-between;

                i
                {
                    font-size: 1.5em;
                    cursor: pointer;
                    margin-left: 10px;
                }
            }

            b
            {
                font-size: 1.5em;
                position: relative;
                display: block;
                z-index: 1;
                padding-left: 20px;
                
                &::before
                {
                    content: '';
                    position: absolute;
                    top: 2px;
                    left: 0;
                    width: 15px;
                    height: 15px;
                    border: 3px solid $dark-blue;
                    border-radius: 50%;
                    background: #fff;
                }
            }

            ul
            {
                list-style-type: none;
                font-size: 1.7em;
                position: relative;
                padding: 15px 0 15px 30px;
                margin: 0;
                color: $muted;

                li
                {
                    cursor: pointer;

                    &:hover
                    {
                        text-decoration: underline;
                    }
                }

                &::before
                {
                    content: '';
                    position: absolute;
                    top: -10px;
                    left: 6px;
                    width: 3px;
                    height: calc(100% + 20px);
                    background: $dark-blue;
                }
            }

            h5
            {
                font-weight: 500;
                font-size: 1.6em;
                position: relative;
                display: block;
                padding-left: 20px;
                margin-bottom: 0;
                
                &::before
                {
                    content: '';
                    position: absolute;
                    top: 2px;
                    left: 0;
                    width: 15px;
                    height: 15px;
                    border: 3px solid $dark-blue;
                    border-radius: 50%;
                    background: #fff;
                }
            }

            @include mq('tablet', max)
            {
                position: fixed;
            }

            @include mq('phablet', max)
            {
                width: calc(100% - 10px);
                left: 5px;
            }
        }

        &-switch
        {
            font-size: 1.6em;
            position: sticky;
            display: flex;
            align-items: center;
            justify-content: center;
            left: 0;
            z-index: 2;
            margin: 20px 0;
            color: #fff;
            
            & > div
            {
                display: flex;
                align-items: center;
                padding: 15px;
                border-radius: 6px;
                background: $dark-blue;
            }

            input[type="checkbox"]
            {
                width: 0;
                height: 0;
                visibility: hidden;
            }

            label
            {
                position: relative;
                cursor: pointer;
                width: 50px;
                height: 25px;
                display:block;
                margin: 0 15px;
                border-radius: 100px;
                transition: 0.5s;
                box-shadow: 0 0 20px #477a8550;
                background-color: #fff;
            }

            label::after
            {
                content: "";
                width: 20px;
                height: 20px;
                position: absolute;
                border-radius: 70px;
                top: 49%;
                left: 3px;
                transition: 0.2s;
                transform: translate(0, -50%);
                background-color: $primary;
            }
            
            input:checked + label:after
            {
                left: calc(100% - 2.5px);
                transform: translate(-100%, -50%);
            }
            
            label:active:after
            {
                width: 25px;
            }
        }

        svg
        {
            width: 100%;
            height: 600px!important;
            height: auto;
            transform: scale(2.5) translate(-250px, 22%);

            .country
            {
                fill: #26336685;
            }

            .city
            {
                cursor: pointer;

                .circle,
                .country-name
                {
                    transition: all .2s ease;
                }

                &.active
                {
                    & > .circle
                    {
                        fill: #fff;
                    }

                    & > .country-name
                    {   
                        fill: $dark-blue;
                    }
                }

                &:hover
                {
                    & > .circle
                    {
                        fill: #fff;
                    }

                    & > .country-name
                    {   
                        fill: $dark-blue;
                    }
                }
            }

            &.uzb
            {
                display: none;
                width: 100%;
                height: 700px!important;
                transform: none;

                
                @include mq('tablet-mid', max)
                {
                    height: auto!important;
                }
                
                @include mq('tablet', max)
                {
                    transform: scale(2) translate(50px, -30px);
                }
            }
            
            @include mq('desktop', max)
            {
                transform: scale(2.5) translate(-150px, 22%);
            }
            
            @include mq('tablet-wide', max)
            {
                transform: scale(4) translate(-96px,16%);
            }

            @include mq('tablet-small', max)
            {
                transform: scale(4) translate(-96px,9%);
            }

            @include mq('phablet', max)
            {
                transform: scale(6) translate(-35px,9%);
            }

            @include mq('phone-wide', max)
            {
                transform: scale(8.5) translate(-35px,8%);
            }

            @include mq('phone', max)
            {
                transform: scale(8.5) translate(-25px,6%);
            }
        }

    }

    .news
    {
        background: $dark-blue;
    }

    &.page
    {
        margin-top: 86px;

        @include mq('tablet-mid', max)
        {
            margin-top: 80px;
        }
    }

    // Different Pages

    .with-sidebar
    {
        p
        {
            font-size: 1.6em;
            margin-bottom: 25px;
        }
    }

    .purchases
    {
        color: $light-muted;

        h6
        {
            font-weight: 500;
            font-size: 1.6em;
            margin-bottom: 20px;
        }
    }

    .about
    {
        img
        {
            object-fit: cover;
            object-position: center;
            width: 100%;
            min-height: 250px;
            border-radius: 6px;
            margin-bottom: 25px;
        }

        p
        {
            font-size: 1.6em;
            margin-bottom: 20px;
        }
    }
}

footer
{
    padding: 80px 0;
    color: #fff;
    margin-top: auto;
    background: $dark-blue;

    .col-md-3
    {
        &:last-child
        {
            margin-bottom: 0;
        }
    }

    h5
    {
        font-size: 1.6em;
        margin-top: 10px;
        margin-bottom: 20px;
    }

    p
    {
        font-size: 1.4em;
        margin-bottom: 0;
    }

    a:hover
    {
        text-decoration: underline;
    }

    ul
    {
        padding: 0;
        list-style: none;
        margin-bottom: 0;

        li
        {
            margin-bottom: 10px;

            &:last-child
            {
                margin-bottom: 0;
            }
        }

        a
        {
            font-size: 1.4em;
        }
    }

    .border-bot
    {
        border-bottom: 1px solid #374372;
    }

    @include mq('tablet', max)
    {
        padding: 50px 0;

        .col-md-3
        {
            margin-bottom: 30px;
        }

        h5
        {
            &::before
            {
                content: '-';
                padding-right: 10px;
            }
            
            &::after
            {
                content: '-';
                padding-left: 10px;
            }
        }
    }
}