header
{
    position: fixed;
    width: 100%;
    height: auto;
    top: 0;
    left: 0;
    z-index: 5;
    transition: all .3s ease;
    background: #fff;
    transform: translateY(0);
    border-bottom: 1px solid $info;

    nav
    {
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        width: 100%;
        height: auto;
        padding: 20px 50px;
        transition: all .3s ease;

        .brand
        {
            font-weight: 500;
            font-size: 2.4em;
            color: #000;
        }

        ul.main
        {
            display: flex;
            padding: 0;
            margin: 0;
            z-index: 1;

            & > li
            {
                list-style-type: none;
                font-weight: 400;
                font-size: 1.6em;
                margin: 0 .25rem;
                border-radius: 4px;
                color: #000;
                transition: all .3s ease;

                a
                {
                    display: block;
                    padding: 8px 10px;
                }
                
                .drop
                {
                    position: absolute;
                    visibility: hidden;
                    overflow: auto;
                    width: 100%;
                    height: auto;
                    max-height: 75vh;
                    top: calc(100% - 30px);
                    left: 0;
                    opacity: 0;
                    padding-top: 30px;

                    .drop-body
                    {
                        padding: 70px 15px;
                        background: #fff;

                        &-info
                        {
                            font-size: 16px;
                            padding: 0 0 15px 0;
                            margin-bottom: 15px;
                            border-bottom: 1px solid $info;
                            color: $muted;

                            a
                            {
                                padding: 0;
                            }

                            i
                            {
                                margin-right: 10px;
                            }
                        }

                        a
                        {
                            transition: all .2s ease;

                            &:hover
                            {
                                text-decoration: underline;
                                color: #000;
                            }
                        }

                        h4
                        {
                            font-weight: 400;
                            font-size: 20px;
                            margin-bottom: 15px;
                            color: #000;
                        }

                        ul
                        {
                            list-style-type: none;
                            padding: 0;
                            margin: 0;
                            color: $muted;

                            li
                            {
                                margin-bottom: 5px;
                            }

                            a
                            {
                                display: inline-block;
                                padding: 0;
                            }
                        }
                    }

                    &.active
                    {
                        visibility: visible;
                        opacity: 1;
                    }
                }
                
                &:hover
                {
                    color: #fff;
                    background: $dark-blue;

                    &>.drop
                    {
                        visibility: visible;
                        opacity: 1;
                        transition: all .2s ease;
                    }
                }
            }
        }

        ul.second
        {
            font-size: 1.6em;
            display: flex;
            align-items: center;
            list-style-type: none;
            padding: 0;
            margin: 0;
            color: #777777;
            z-index: 1;

            li
            {
                position: relative;
                margin: 0 .625rem;

                i.main
                {
                    padding-right: 10px;
                }

                &:nth-child(2)
                {
                    margin: 0;
                    padding: 0 .5rem;
                    border-left: 1px solid #d7d7d7;
                    border-right: 1px solid #d7d7d7;
                }
            }

            a
            {
                display: flex;
                justify-content: space-between;
                align-items: center;

                img
                {
                    width: 18px;
                    margin-right: 10px;
                }

                div
                {
                    display: block;
                }

                .valute-icon.fa-sort-down
                {
                    color: $danger;
                }

                .valute-icon.fa-sort-up
                {
                    color: $success;
                }

                div
                {
                    display: inline-block;
                    padding: 10px;
                    border: 1px solid #0000003b;
                    border-radius: 5px;
                }
            }

            .view-search
            {
                cursor: text;
            }

            .main-search
            {
                overflow: hidden;
                position: absolute;
                height: 0px;
                width: 50vw;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
                transition: all .15s ease;

                input
                {
                    width: 100%;
                    height: 50px;
                    border: none;
                    outline: none;
                    padding: 0 30px;
                    border-bottom: 1px solid #a9a9a9;
                }

                button
                {
                    position: absolute;
                    top: 50%;
                    left: 1px;
                    border: 0;
                    outline: 0;
                    transform: translateY(-50%);
                    background: transparent;
                }

                .fa-times
                {
                    cursor: pointer;
                    position: absolute;
                    top: 50%;
                    right: 8px;
                    transform: translateY(-50%);
                }

                &.active
                {
                    height: 50px;
                }

                @include mq('desktop-wide', max)
                {
                    width: 47vw;
                }

                @include mq('desktop')
            }
        }

        .mob-nav
        {
            visibility: hidden;
            position: fixed;
            display: flex;
            width: 100vw;
            height: 100vh;
            top: 0;
            left: 0;
            z-index: 99;
            transition: all .5s;

            .overlay
            {
                visibility: hidden;
                position: fixed;
                width: 100vw;
                height: 100vh;
                top: 0;
                left: 0;
                opacity: 0;
                z-index: 1;
                background: #000000a1;
                transition: all .5s ease;
            }

            &-content
            {
                overflow: auto;
                position: relative;
                max-width: 300px;
                width: 300px;
                height: 100vh;
                z-index: 2;
                transform: translateX(-100%);
                background: #fff;
                transition: all .4s ease;
            }

            ul
            {
                font-size: 1.4em;
                display: flex;
                flex-direction: column;
                list-style-type: none;
                padding: 15px 20px;
                margin: 0;

                li
                {
                    padding-bottom: .6rem;
                    margin-bottom: .6rem;
                    border-bottom: 1px solid #d3d3d3;
                }

                a
                {
                    display: inline-block;
                    width: 100%;
                }

                a.main
                {
                    font-weight: 500;
                    font-size: 1.2em;
                    color: $dark-blue;
                }

                i.fa-chevron-down
                {
                    font-size: 12px;
                    color: $muted;
                }
            }

            img
            {
                width: 25px;
            }

            .mobile-search
            {
                position: relative;
                margin-bottom: 15px;

                i
                {
                    color: #fff;
                }

                input
                {
                    height: auto;
                    padding-top: 30px;
                    padding-bottom: 30px;
                    padding-left: 45px;
                    border: 0;
                    border-bottom: 1px solid #a9a9a9;
                    border-radius: 0;
                    background: $dark-blue;
                    color: #fff;

                    &::placeholder
                    {
                        font-weight: 300;
                        color: $info;
                    }
                }

                button
                {
                    font-size: 1.8em;
                    position: absolute;
                    top: 50%;
                    left: 10px;
                    border: 0;
                    outline: 0;
                    transform: translateY(-50%);
                    background: transparent;
                }
            }

            .app-drop-mob-body
            {
                display: flex;
                flex-direction: column;
            }

            &.active
            {
                visibility: visible;

                .mob-nav-content
                {
                    transform: translateX(0);
                }

                .overlay
                {
                    visibility: visible;
                    opacity: 1;
                }
            }
        }

        .notification
        {
            text-align: center;
            font-size: 1.6em;
            position: absolute;
            top: 100%;
            left: 0;
            width: 100%;
            height: auto;
            padding: 15px;
            background: #FFDA00;

            a
            {
                text-decoration: underline;
            }

            i
            {
                cursor: pointer;
                position: absolute;
                top: 50%;
                right: 0;
                transform: translateY(-50%);
                color: $light-muted;
            }
        }

        @include mq('desktop-small', max)
        {
            padding: 10px 15px;

            ul li
            {
                font-size: 1.2em;
            }

            .brand img
            {
                width: 200px;
            }

            ul.main
            {
                display: none;
            }

            ul.second
            {
                display: none;
            }
        }

        @include mq('tablet-mid', max)
        {
            ul
            {
                display: none;
            }
        }

        @include mq('phablet', max)
        {
            .brand img
            {
                width: 150px;
            }
        }
    }

    &.second
    {
        background: #000;
    }

    &.scrolled
    {
        transform: translateY(-100%);

        nav
        {
            @include mq('tablet-mid', max)
            {
                padding: 0 15px;
            }
        }
    }
}